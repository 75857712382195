
.App{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: var(--bg);
}

.mainContainer{
  width: 100%;
  height: 100%;
  padding-left: 250px;
  padding-top: var(--header);
  transition: 0.3s;
}

.mainContainerAus{
  padding-left: 70px;
}