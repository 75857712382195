.menu{
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--white);
    border-right: 1px solid var(--borderColor);
    z-index: 350;
    overflow: hidden;
}

.aside-body{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    position: relative;
}

.aside-body::-webkit-scrollbar {
    display: none;
}

.asideHeader{
    height: var(--header);
    width: 100%;
    min-width: 110px;
    padding: 0 23px;
}

.logoimg{
    height: 30px;
    width: max-content;
    margin-right: 12px;
}

.asideItem{
    width: 100%;
    height: max-content;
    padding: 10px 23px;
    cursor: pointer;
}


.asideItemIconContainer{
    height: max-content !important;
    width: max-content !important;
}
.asideItemIcon{
    stroke: var(--headerItemColor);
    height: 22px;
    width: max-content;
    margin-right: 15px;
    cursor: pointer;
}

.divTitle{
    font-family: var(--mainFont);
    font-size: 11px;
    margin: 10px 0;
    margin-left: 23px;
    color: var(--black);
}


.asideActive{
    color: var(--primary);
    stroke: var(--primary);
    font-weight: 600;
    stroke-width: 2px;
}
.asideItem:hover .asideItemIcon{
    stroke: var(--primary);
    stroke-width: 2px;
}
.asideItem:hover .asidep{
    color: var(--primary);
    font-weight: 600;
}








.asidepAus{
    max-width: 0px;
    overflow: hidden;
}
.menuAus{
    width: 70px;
    height: 100vh;
}
.asideItemIconAus{
    margin: 0;
}

.divTitleAus{
    display: none;
}
.adminTit{
    display: none;
}
.asideHeaderAus{
    padding: 0 19px;
    margin-bottom: 20px;
}



.CloseAsideContainer{
    display: none !important;
}

.ordersAsideItems{
    height: 0;
    padding: 0;
    display: none;
}
.ordersAsideItemsActive{
    padding-top: 15px;
    padding-left: 37px;
    height: max-content;
    display: block;
}

.ordersAsideItem{
    margin-bottom: 10px;
}

.ordersAsideItem:hover{
    color: var(--primary);
    font-weight: 600;
}
.ordersAsideItemsActive2{
    color: var(--primary);
    font-weight: 600;
}





@media (max-width: 1200px) {
    .CloseAsideContainer{
        display: block !important;
    }
}

@media(max-width: 500px){
    .menuAus{
        left: -100%;
    }
    .mainContainerAus{
        padding-left: 0;
    }
    .mainHeaderAus{
        width: 100% !important;
    }
}