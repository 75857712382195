.LogInScreen{
    height: 100vh;
    width: 100%;
    background-color: var(--bg);
}

.LoginTitle{
    margin-bottom: 40px;
}
.LogFormContainer{
    padding: 0 20px;
}

.LogInputsContaine{
    width: 100%;
}

.LogActionsContainer{
    width: 100%;
    margin-top: 20px;
}

#loginButton{
    width: 100%;
    height: max-content;
}

.LoginLogo{
    width: 45px;
    margin-right: 10px;
}