.DrawerContainer{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 360;
    display: none;
}
.DrawerContainerActive{
    display: block;
}

.DrawerBackdrop{
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100%;
    transition: 0.3s;
    opacity: 0;
}
.DrawerBackdropActive{
    opacity: 1;
}

.DrawerBody{
    width: 450px;
    height: 100%;
    background-color: var(--white);
    position: fixed;
    top: 0;
    right:-450px;
    transition: 0.3s;
}
.DrawerBodyActive{
    right: 0px;
}