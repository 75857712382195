.emp-card-avatar{
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: var(--primary);
}

.emp-card-avatar-head{
    width: 38px;
    height: 38px;
    border-radius: 50%;
}

.empcard-ut-schi{
    font-size: 11px;
}

.empcardiconcont1{
    background-color: rgba(235, 53, 89, 0.9);
    height: 27px;
    width: 27px;
    border-radius: 50%;
}


.empcardiconcont2{
    background-color: rgba(20, 220, 190);
    height: 27px;
    width: 27px;
    border-radius: 50%;
}


.employees-cards-transtion{
    transition: 0.3s;
}

.closedCard{
    overflow: auto;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
}

.headActiveAvatar{
    border: rgb(80, 5, 254) 2px dashed;
    border-radius: 50%;
    padding: 1px;
    -webkit-animation: heartbeat 1.5s ease-in-out  both;
    animation: heartbeat 1.5s ease-in-out  both;
}


.EmployeeEdit-avatar-container{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: brown;
}

.image-edit-icon{
    width: 24px;
    height: 24px;
    background-color: cadetblue;
    border-radius: 50%;
}


.userRoleCard{
    padding: 10px;
    background-color: var(--Lgray2);
    width: 100%;
    border-radius: 3px;
    filter: drop-shadow(rgba(0, 0, 0, 0.18) 0 0 3px);
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
    color: var(--black) !important;
}

.input-mui{
    color: var(--black) !important;
}

.MuiSvgIcon-root{
    color: var(--black) !important;
}


.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    color: var(--black) !important;
}

.adduserbtn{
    background: var(--primary);
    border-radius: 3px;
}


.employees-cards-body-head{
    position: sticky;
    top: var(--header);
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.2) 0 6px 5px !important;
}




@-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
}
@keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
}
  













@media (max-width: 768px) {
    .EmployeeEdit-avatar-container{
        width: 60px;
        height: 60px;
    }

    .userRoleCard{
        padding: 7px;
    }
}


@media (max-width: 500px) {
    .userRoleCard{
        padding: 3px;
    }
    .userRoleCard p{
        font-size: 12px !important;
    }
}