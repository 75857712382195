.stat-button{
    background-color: var(--Lgray2) !important;
    border-color: var(--Lgray) !important;
    color: var(--black) !important;
}

.stat-button-active{
    background-color: var(--primary) !important;
    border-color: var(--Lgray) !important;
    color: var(--white) !important;
}

.stat-filter-btn{
    height: 36px !important;
    background-color: var(--primary) !important;
    color: var(--white) !important;
    overflow: hidden;
    position: relative;
}

.date-drop-menu{
    width: 220px;
    background-color: cadetblue;
    top: 100%;
    right: 0;
    position: absolute;
}

.date-drop-menu-item{
    width: 100%;
    height: 60px;
    border-bottom: var(--borderColor);
}

.selectDate{
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    opacity: 0;
}