.ODE-Calender{
    height: calc(100vh - 113px);
    width: 100%;
}

.Calender-container{
    width: 100%;
    height: 100%;
    grid-template-columns: max-content 1fr;
    gap: 10px;
}

.CalenderHeader{
    margin-bottom: 20px;
}

.CalenderMWDContainer{
    width: max-content;
    height: max-content;
}

.CalenderMWD{
    height: 35px;
    min-width: 50px;
    padding: 0 15px;
    background-color: var(--primary);
    opacity: 0.7;
    cursor: pointer;
    color: var(--white);
}
.CalenderMWD:first-child{
    border-radius: 3px 0 0 3px;
}
.CalenderMWD:last-child{
    border-radius: 0 3px 3px 0;
}

.CalenderMWDActive {
    opacity: 1;
}