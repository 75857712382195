.NewTaskContainer{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    background-color: var(--white);
    z-index: 99;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.NewTaskContainer::-webkit-scrollbar {
    display: none;
} 
/* Hide scrollbar for IE, Edge and Firefox */
.NewTaskContainer {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


.NewTaskHeader{
    width: 100%;
    height: 70px;
    padding: 25px;
    border-bottom: 1px var(--Lgray) solid;
}


.NewTaskInput{
    width: 100%;
    border: none;
    outline: none;
    color: var(--black);
    font-family: var(--mainFont);
    font-size: 17px;
    background: none;
}

.taskInhalt{
    width: 100%;
    padding: 25px 25px 75px 25px;
    border-bottom: 1px var(--Lgray) solid;
    min-height: 50%;
    position: relative;
}

.NewTaskTagsContainer{
    width: 100%;
    padding: 25px;
    border-bottom: 1px var(--Lgray) solid;
}

.newTaskTag{
    width: max-content;
    padding: 8px 16px;
    border-radius: 3px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    cursor: pointer;
}

.newTaskTagActive{
    outline: var(--black) 1px solid;
}


.Statusselect{
    outline: none !important;
    width: 100%;
    height: 56px;
    padding: 0 15px;
    border-radius: 3px;
    background-color: var(--white);
    color: var(--black);
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-color: var(--gray) !important;    
}

.MuiInputLabel-root{
    color: var(--gray) !important;
}

.css-i4bv87-MuiSvgIcon-root{
    fill: var(--gray) !important;   
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    color: var(--black) !important;
}


.spinnerSave{
    border-color: var(--white) !important;
    border-right-color: transparent !important;
}


/* .tox .tox-edit-area__iframe{
    background-color: var(--white) !important;
}

.tox .tox-toolbar-overlord {
    background-color: var(--Lgray) !important;
} */

